<template>
  <v-container grid-list-xs class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text">
      <v-col md="8" sm="12" class="pa-0 ma-0 mt-1">
        <span class="pl-1 text-light font-size-2">{{tituloGrids}}</span>
      </v-col>
       <v-col md="12" sm="12" cols="12" class="pa-0 ma-0">
        <v-breadcrumbs
          class="py-2 px-0"
          :items="aItemsBreadcrumbs"
          divider=">"
        >
         <template v-slot:item="{ item }">
          <v-breadcrumbs-item class="titlesdark--text">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-if="!mostrarFormularioNuevo" class="pa-0 ma-0">
      <v-col md="12" sm="12" class="pa-0 ma-0">
        <grid
          v-if="this.$store.state.token!=''"
          :tabla="TablaName"
          :Detalles="'DetalleActaReunionPqr'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :sIdTable="'idActaReunion'"
          :bShowMapDetails="false"
          :bActiveNew="false"
          :aInfoTBDetalleExp="aDetallesExport"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect">
            <template v-slot:slotbtnNew>
              <v-btn v-if="oPermisos.priv_insert=='1'" class="no-uppercase btn-rounded d-flex mx-2 my-1" small depressed dark  color="btndest" @click="onMostarModal">Nueva Acta</v-btn>
            </template>
            <!--template v-slot:slot-dialog-firmas="oProps">
              <dialog-firmas
                v-if="oProps.isModal"
                :bMostrarModal="oProps.isModal"
                :oDataParent.sync="oProps.oItem"
                :sNombreTabla="'COPSECANCOL_Asistentes_Acta_Reunion'"
                :sIdCampo="'idActaReunion'"
                :oCampos="oCamposFirmasActaReunion"
                @input="oProps.onCerrarDialog()"
              />
            </template-->
        </grid>  
      </v-col>
        <v-col md="12" sm="12" cols="12" class="pa-0 ma-0 mt-2">
          <grid-detalle
            v-if="parseInt(nIdSeleccionado) != 0"
            :tabla="'COPSECANCOL_Compromisos_Reunion'"
            :sIdTable="'idCompromisos_Reunion'"
            :bActiveFilter="false"
            :bActiveExport="false"
            :bSingleSelect="false"
            :bShowSelect="false"
            :bShowMapDetails="false"
            :bActiveSearch="false"
            :bActiveNew="false"
            :bGridDetalle="true"
            :bGridAnidada="true"
            :nIdValor="parseInt(nIdSeleccionado)"
            :sCampoFiltro="'actareunionid'"
          >

            <template v-slot:slot-grid-anidada="props" >
                <grid-detalle
                  v-if="onExtraerIdSelectGridAnidada(props.item)!=0"
                  :tabla="'COPSECANCOL_Compromisos_Historico'"
                  :sIdTable="'idHistorico'"
                  :bActiveFilter="false"
                  :bActiveExport="false"
                  :bSingleSelect="false"
                  :bShowSelect="false"
                  :bShowMapDetails="false"
                  :bActiveSearch="false"
                  :bActiveNew="false"
                  :bGridDetalle="true"
                  :nIdValor="onExtraerIdSelectGridAnidada(props.item)"
                  :sCampoFiltro="'compromisoid'"
                />
            </template>
          </grid-detalle>
        </v-col>
    </v-row>

    <nueva-acta-reunion-pqr 
      v-else
      v-model="mostrarFormularioNuevo" :key="nKeyReset"
    />
  </v-container>
</template>

<script>
import grid from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
import NuevaActaReunionPqr from '@/components/Seif/operaciones/NuevaActaReunionPqr.vue'
import GridDetalle from '@/components/Grid/Grid.vue'
// import DialogFirmas from '@/components/DialogFirmas.vue' 

export default {
  name: 'ActaReunionPQR',
  components: {
    grid,
    NuevaActaReunionPqr,
    GridDetalle,
    // DialogFirmas
  },
  mixins: [mxFunciones],
  data() {
    return {
      TablaName : 'COPSECANCOL_Actas_reunion',    // Nombre de la tabla a trabajar
      nIdSeleccionado  : 0,                 // Id seleccionado
      sNombreCampoFiltro : '',                 // Nombre del campo foreignKey
      mostrarFormularioNuevo : false,
      nKeyReset : 0,
      aItemsBreadcrumbs: [
        {
          text: 'Inicio',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Operaciones',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: '',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
      ],
      aDetallesExport : [
        { 
          sTablaDetalle: 'COPSECANCOL_Compromisos_Reunion',
          sNombreCampoFiltroDetalle: 'actareunionid',
          sCampoParaFiltroTBPrincipal: 'idActaReunion',
        }

      ],
    }
  },
  async mounted() {
    this.onValidarTokenSinLogin()
    this.tituloGrids = await this.getTitulo(this.TablaName)
    this.aItemsBreadcrumbs[2].text = this.tituloGrids
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.nIdSeleccionado = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.sNombreCampoFiltro = sNameFiled;
    },
    createDetaill() {
  
    },
    onMostarModal() {
      this.mostrarFormularioNuevo = true
      this.nKeyReset++
      this.aItemsBreadcrumbs.push({
        text: 'Crear ' + this.tituloGrids,
        disabled: true,
        href: 'breadcrumbs_dashboard',
      })
    }
  },
  watch: {
    mostrarFormularioNuevo(bNuevoValor) {
      if(!bNuevoValor){
        this.aItemsBreadcrumbs.splice(3,1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>